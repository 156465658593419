import * as React from 'react'
import { Link, HeadFC, graphql } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import { FontAwesomeIcon, solidIcons } from '../components/icons'
const { faArrowRight } = solidIcons

import Layout from '../components/layout'

const NotFoundPage = () => {
  return (
    <Layout>
      <section className="flex h-screen flex-col items-center justify-center bg-gray-200">
        <div className="m-4 text-6xl text-gray-800">404</div>
        <div className="mb-4 text-3xl font-light text-gray-700">
          <Trans>not-found</Trans>
        </div>
        <div className="text-xl text-amber-500">
          <Link to="/">
            <Trans>go-home</Trans>
            <FontAwesomeIcon
              icon={faArrowRight}
              className="ml-2 text-amber-500"
            />
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => {
  return <title>404</title>
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
